/* Main Js */

if (module.hot) {
    module.hot.accept();
}

//Imports
import $ from "jquery"
import AOS from "aos"
import { Toast } from 'bootstrap'
import Swal from "sweetalert2"
import counterup2 from "counterup2"
import 'simplebar'
import ResizeObserver from 'resize-observer-polyfill'
window.ResizeObserver = ResizeObserver
//import mapPin from "../images/icon/MapPin.svg"


window.AOS = AOS
window.$ = $
window.jquery = $
window.jQuery = $
window.Swal = Swal
AOS.init({
    once: true
})

const toastTrigger = document.getElementById('clipboard')

const toastLiveExample = document.getElementById('copyNewsLink')
if (toastTrigger) {
    toastTrigger.addEventListener('click', () => {
        const toast = new Toast(toastLiveExample)

        toast.show()
    })
}

// Header js
const headerMenu = () => {
    const menuItems = document.querySelectorAll('.header .menu .sub-menu');
    const menuToggleEl = document.querySelector('.menu-toggle');
    const toggleClass = "active";
    const openClass = "open-menu"

    menuToggleEl.addEventListener('click', (e) =>  {
        e.preventDefault()
        menuToggleEl.querySelector('.hamburger-menu').classList.toggle(toggleClass)
    })

    const menuShow = (item) => {
        item.target.querySelector('.sub-menu-item').classList.add(openClass)
    }

    const menuHide = (item) => {
        item.target.querySelector('.sub-menu-item').classList.remove(openClass)
    }

    menuItems.forEach(menuItem => {
        menuItem.addEventListener('mouseenter', menuShow)
        menuItem.addEventListener('mouseleave', menuHide)
    })
}

headerMenu();

// Go up
const goUp = () => {
    const el = document.getElementById('go-up')

    if (el === undefined || el === null) return false

    el.addEventListener('click', event => {
        event.preventDefault()
        console.log(event.target)
        window.scrollTo({ top: 780, behavior: 'smooth' })
    })

}

goUp()

// Sticky Header
window.addEventListener('scroll', (e) => {
    const headerEl = document.querySelector('header.header')

    if (window.scrollY > 90) {
        headerEl.classList.add('sticky')
    } else {
        headerEl.classList.remove('sticky')
    }
})

// Tab

const tabMenu = () => {
    const tabMenuItems = document.querySelectorAll('[data-tab-menu]')
    const toggleClass = "active"

    tabMenuItems.forEach((item, index) => {
        item.addEventListener('click', (e) => {
            let target = e.currentTarget.dataset.tabTarget,
                menu = e.currentTarget.dataset.tabMenu,
                root = menu

            document.querySelectorAll('[data-tab-menu="' + menu + '"]').forEach(el => {
                el.classList.remove(toggleClass)
            })

            e.currentTarget.classList.add(toggleClass)

            document.querySelectorAll('[data-tab-root="' + root + '"]').forEach(el => {
                el.classList.remove(toggleClass)
            })

            document.getElementById(target).classList.add(toggleClass)

        })
    })
}

tabMenu()

const counterUp = () => {
    const callback = entries => {
        entries.forEach( entry => {
            const el = entry.target
            if ( entry.isIntersecting) {
                counterup2( el, {
                    duration: 1500,
                    delay: 16,
                })
            }
        })
    }

    const IO = new IntersectionObserver( callback, { threshold: 1 } )

    const counterEl = document.querySelectorAll( '.page-statistics-item .counter' )
    counterEl.forEach(el => IO.observe(el))

    const processWidget = document.querySelector('.process-widget .process-widget-text strong')

    if ( processWidget !== null) {
        IO.observe(document.querySelector('.process-widget .process-widget-text strong'))
    }
}

counterUp()

const filterCheckBox = () => {
    const elems = document.querySelectorAll('.projects-filter .checkbox-group-item')
    const toggleClass = "checked"

    const filterCheck = (e) => {
        let input = e.currentTarget.querySelector('input'),
            item = e.currentTarget.querySelector('.checkbox-item')

        if (input.checked) {
            input.checked = false
            item.classList.remove(toggleClass)
        } else {
            input.checked = true
            item.classList.add(toggleClass)
        }

        input.dispatchEvent(new Event('change'))

    }

    elems.forEach(el => {
        el.addEventListener('click', filterCheck)
    })
}

filterCheckBox()

const projectAnimateLoading = () => {
    const target = document.querySelectorAll('.process-widget .process-widget-loading')

    if (target === null) return false

    setTimeout(() => {

        for (let i = 0; i < target.length; i++) {
            target[i].classList.remove('stop-animate')
        }
    }, 750)
}

projectAnimateLoading()

const projectDetailPlans = () => {
    const buttons = document.querySelectorAll('.apartment-plans-menu-items .apartment-plans-menu-item')
    const buttonsChild = document.querySelectorAll('.apartment-plans-menu-childs .apartment-plans-menu-child')
    const infoBoxItems = document.querySelectorAll('.apartment-plans .info-box-items .info-box-item')
    const gallery = document.querySelectorAll('.apartment-plans-gallery-group .apartment-plans-gallery-items')
    const toggleClass = "active"

    const buttonsToggle = (e, i) => {
        buttons.forEach((button, index) => {
            button.classList.remove(toggleClass)
        })

        buttons[i].classList.add(toggleClass)
    }

    const buttonsChildToggle = (e, i) => {
        buttonsChild.forEach((child, index) => {
            child.classList.remove(toggleClass)
        })

        buttonsChild[i].classList.add(toggleClass)
    }

    const infoBoxItemsToggle = (e, i) => {
        infoBoxItems.forEach((item, index) => {
            item.classList.remove(toggleClass)
        })

        infoBoxItems[i].classList.add(toggleClass)
    }

    const galleryToggle = (e, i) => {
        gallery.forEach((item, index) => {
            item.classList.remove(toggleClass)
        })

        gallery[i].classList.add(toggleClass)
    }

    buttons.forEach((button, index) => {
        button.addEventListener('click', event => {
            buttonsToggle(event, index)
            //buttonsChildToggle(event, index)
            infoBoxItemsToggle(event, index)
            galleryToggle(event, index)
        })
    })
}

projectDetailPlans()

// Window initialization
const productDetailMaps = () => {
    const mapEl = document.getElementById("project-detail-map")
    if (mapEl === null) return false
    let data = MAPDATA

    console.log(data)

    const createHtmlItem = (item, index) => {
        let html = `
        <div class="map-item-container">
            <div class="map-item">
              <h4>${item.title}</h4>
              <p class="item"><i class="ph-light ph-map-pin"></i><span>${item.address}</span></p>
              <a class="item" href="tel:${item.phone}" title="${item.phone}"><i class="ph-light ph-phone-call"></i><span>${item.phone}</span></a>
              <a class="link" href="${item.url.url}" title="${item.url.url}"><span>${item.url.title}</span> <i class="ph-light ph-caret-right"></i></a>
            </div>
          </div>
        `

        return html
    }

    const googleMaps = () => {
        let markers,infoWindows;

        let styles = [
            {
                "featureType": "administrative",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "administrative.province",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 65
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": "50"
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "30"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "lightness": "40"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "hue": "#ffff00"
                    },
                    {
                        "lightness": -25
                    },
                    {
                        "saturation": -97
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels",
                "stylers": [
                    {
                        "lightness": -25
                    },
                    {
                        "saturation": -100
                    }
                ]
            }
        ]

        markers = []
        infoWindows = []

        let styledMap = new google.maps.StyledMapType(styles, {
            name: "ATHENA YAPI"
        });

        let map = new google.maps.Map(mapEl, {
            center: new google.maps.LatLng(39.0704333, 32.9352979),
            zoom: 7,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.TERRAIN, 'map_style']
            }
        });

        map.mapTypes.set('map_style', styledMap);
        map.setMapTypeId('map_style');

        if (!data.length) return false

        data.forEach((mapData, index) => {

            let infoWindow = new google.maps.InfoWindow({
                content: createHtmlItem(mapData, index)
            })

            let location = mapData.location
            let marker = new google.maps.Marker({
                draggable: false,
                map: map,
                animation: google.maps.Animation.DROP,
                position: new google.maps.LatLng(location.latitude, location.longitude),
                title: mapData.title,
                /*icon: {
                    scaledSize: new google.maps.Size(43, 43),
                    url: mapPin
                }*/
            })

            marker.addListener('click', function () {
                for (let infoWin = 0; infoWin < infoWindows.length; infoWin++) {
                    infoWindows[infoWin].close()
                }
                infoWindow.open(map, marker);
            });

            markers.push(marker);
            infoWindows.push(infoWindow);
        })
    }

    googleMaps()
}

const fileSelect = () => {
    let fileSelect = document.querySelector('.__file-select');

    if (fileSelect === null) return false

    let fileInput = fileSelect.querySelector('input'),
      fileChange = fileSelect.querySelector('.__file-change'),
      fileChanged = fileSelect.querySelector('.__file-changed'),
      fileRemove = fileSelect.querySelector('.__file-remove');

    fileChange.addEventListener('click', (event) => {
        fileInput.click();
    })

    fileInput.addEventListener('change',  (e) => {
        let file = e.target.files[0];
        fileChange.style.display = "none"
        fileChanged.style.display = "flex"
        fileChanged.innerHTML = file.name
        fileRemove.style.display = "flex"
    })

    fileRemove.addEventListener('click',  () => {
        fileChanged.style.display = "none"
        fileChanged.innerHTML = ""
        fileChange.style.display = "flex"
        fileRemove.style.display = "none"
        fileInput.value = null
    })
}

fileSelect()

// Window initialization
window.addEventListener('load', () => {
    productDetailMaps()
})