/* Swiper Slide */
import Swiper from 'swiper/swiper-bundle.min.mjs'

// Hero Slide
const homeBannerElClassName = ".home-banner-slide"
const homeBanner = new Swiper(`${homeBannerElClassName} .swiper`, {
    loop: false,
    allowTouchMove: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: false
    },
    speed: 500,
    effect: 'fade',
    fadeEffect: {
        crossFade: true
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: 'true',
    },

})

const homeLogosSlider = ".logos-container"
const homeLogos = new Swiper(`${homeLogosSlider} .swiper`, {
    slidesPerView: 1,
    spaceBetween: 10,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    autoplay: {
      delay: 3000,
    },
    speed: 500,
    breakpoints: {
        0: {
            slidesPerView: 1.3,
            spaceBetween: 10,
        },
        640: {
            slidesPerView: 2,
            spaceBetween: 10,
        },
        768: {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        1024: {
            slidesPerView: 6,
            spaceBetween: 20,
        },
    },

})

window.addEventListener('resize', () => {

})