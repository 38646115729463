/* Google Maps */

const contactMap = () => {

    const defaultMapEl = document.getElementById('map');

    let styles = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#f5f5f5"
                }
            ]
        },
        {
            "featureType": "administrative.land_parcel",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#bdbdbd"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#757575"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#dadada"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#616161"
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        },
        {
            "featureType": "transit.line",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#e5e5e5"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#c9c9c9"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9e9e9e"
                }
            ]
        }
    ]

    if (defaultMapEl !== null) {
        let map_customize = {
            title: contactMapData.title,
            html: contactMapData.html,
            icon: contactMapData.icon,
            lat: contactMapData.lat,
            lng: contactMapData.lng
        }

        const styledMapStatus = contactMapData.styledMapStatus;

        const styledMap = new google.maps.StyledMapType(styles, {
            name: map_customize.title
        });

        const map = new google.maps.Map(document.getElementById('map'), {
            center: new google.maps.LatLng(map_customize.lat, map_customize.lng),
            zoom: 15,
            mapTypeControlOptions: {
                mapTypeIds: [google.maps.MapTypeId.TERRAIN, 'map_style']
            }
        });

        if (styledMapStatus) {
            map.mapTypes.set('map_style', styledMap);
            map.setMapTypeId('map_style');
        }

        const infoWindow = new google.maps.InfoWindow({
            content: map_customize.html
        });

        const marker = new google.maps.Marker({
            draggable: false ,
            map: map,
            position: new google.maps.LatLng(parseFloat(map_customize.lat), parseFloat(map_customize.lng)),
            title: map_customize.title,
            icon: {
                scaledSize: new google.maps.Size(75, 75),
                url: map_customize.icon
            }
        });

        marker.addListener('click', function () {
            infoWindow.open(map, marker);
        });
    }
}

window.addEventListener('load', () => {
    contactMap()
})