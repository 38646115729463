/* Mobile Menu */
const mobileMenu = () => {
    const activeClass = "active"
    const mobileMenuToggleEl = document.querySelector('.menu-toggle')
    const mobileMenuCloseEl = document.querySelector('.mobile-menu-close-btn')
    const mobileMenuEl = document.querySelector('.mobile-menu')
    const submenuToggleEl = document.querySelectorAll('.mobile-menu .sub-menu-item')
    const mobileMenuTarget = document.getElementById('mobile-menu-navigation-container')
    const mobileMenuBackBtnEl = document.querySelector('.mobile-menu .back')
    const mobileMenuRoot = document.querySelector('.mobile-menu .mobile-menu-root-menu')
    const bodyEl = document.querySelector('body')

    const mobileMenuToggle = (event) => {
        event.preventDefault()
        setTimeout(() => {
            mobileMenuEl.classList.add("active-step-2")
            mobileMenuEl.classList.toggle(activeClass)
        },200)
        bodyEl.style.overflowY = "hidden"
    }

    const mobileMenuClose = (event) => {
        event.preventDefault()
        mobileMenuEl.classList.remove(activeClass)
        bodyEl.style.overflowY = ""

        setTimeout(() => {
            mobileMenuEl.classList.remove("active-step-2")
        }, 200)

        setTimeout(() => {
            document.querySelector('.hamburger-menu').classList.remove(activeClass)
        }, 250)
    }

    const subMenuToggle = (event) => {
        event.preventDefault()
        let html = event.currentTarget.parentNode.querySelector('ul'),
            clone = html.cloneNode(true)

        mobileMenuTarget.innerHTML = ""
        mobileMenuTarget.appendChild(clone)
        mobileMenuBackBtnEl.style.display = "flex"
        mobileMenuRoot.style.display = "none"
    }

    const backAction = (event) => {
        event.preventDefault()
        mobileMenuTarget.innerHTML = ""
        mobileMenuBackBtnEl.style.display = "none"
        mobileMenuRoot.style.display = "block"
    }

    mobileMenuBackBtnEl.addEventListener('click',backAction)

    mobileMenuToggleEl.addEventListener('click', mobileMenuToggle);

    mobileMenuCloseEl.addEventListener('click', mobileMenuClose);

    submenuToggleEl.forEach( menuItem => {
        menuItem.addEventListener('click', subMenuToggle);
    })

}

mobileMenu();